import React, { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from '@reach/router'
import * as yup from 'yup'

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider'
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer'
import Title from '../Title'
import Form from '../Form'
import Input from '../Input'
import SubmitButton from './SubmitButton'

function ChangePassword() {
  const { user } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const navigate = useNavigate()

  const { register, handleSubmit, errors, reset } = useForm({
    mode: 'onBlur',
    validationSchema: yup.object().shape({
      password: yup
        .string()
        .min(8, 'ความยาวขั้นต่ำ 8 ตัวอักษร')
        .required('โปรดระบุรหัสผ่าน'),
      passwordConfirmation: yup
        .string()
        .oneOf([yup.ref('password')], 'โปรดระบุรหัสผ่านให้ตรงกัน'),
    }),
  })

  const submit = useCallback(
    async ({ password }) => {
      try {
        await user.updatePassword(password)
        reset()
        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'success',
            title: 'การดำเนินการสำเร็จ',
            message: 'รหัสผ่านของคุณถูกเปลี่ยนเรียบร้อยแล้ว',
          },
        })
      } catch (error) {
        if(error.code === 400 && error.message === 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN') {
          dispatch({ type: actions.logout })
          dispatch({
            type: actions.showPopupMessage,
            popupMessage: {
              type: 'danger',
              title: 'การร้องขอไม่สมบูรณ์',
              message: 'โปรดเข้าสู่ระบบอีกครั้งก่อนทำการเปลี่ยนรหัสผ่านใหม่',
            },
          })
          navigate('/auth/sign-in', {
            state: { prevPath: '/auth/profile' },
          })
        }
      }
    },
    [user, dispatch]
  )

  return (
    <>
      <Title>แก้ไขรหัสผ่าน</Title>
      <Form onSubmit={handleSubmit(submit)}>
        <Input
          type="password"
          name="password"
          title="รหัสผ่าน"
          ref={register}
          placeholder="Password"
          autoComplete="current-password"
          error={errors.password}
        />
        <Input
          type="password"
          name="passwordConfirmation"
          title="รหัสผ่านอีกครั้ง"
          ref={register}
          placeholder="Confirm Password"
          autoComplete="current-password"
          error={errors['passwordConfirmation']}
        />
        <SubmitButton>เปลี่ยนรหัสผ่าน</SubmitButton>
      </Form>
    </>
  )
}

export default ChangePassword
