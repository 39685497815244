import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

import SubmitButton from '../SubmitButton'

const StyledSubmitButton = styled(SubmitButton)`
  ${({ theme }) => css`
    padding: ${theme.spacers.small};

    ${up('medium')} {
      width: max-content;
      margin-left: auto;
    }
  `}
`

export default StyledSubmitButton
