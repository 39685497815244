import React from 'react'
import styled from 'styled-components'

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import EditProfile from './EditProfile'
import ChangePassword from './ChangePassword'

const Wrapper = styled.div`
  max-width: 768px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spacers.normal};
  width: 100%;
`

function AuthProfile() {
  return (
    <AuthGuard>
      <Wrapper>
        <EditProfile></EditProfile>
        <ChangePassword></ChangePassword>
      </Wrapper>
    </AuthGuard>
  )
}

export default AuthProfile
