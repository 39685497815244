import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import {
  getFirebaseAuth,
  getFirebaseStorage,
  getFirebaseFirestore,
} from '@babelcoder/gatsby-plugin-firebase';
import * as yup from 'yup';

import {
  useGlobalState,
  useGlobalDispatch,
} from '@babelcoder/gatsby-theme-base/src/components/GlobalStateProvider';
import { actions } from '@babelcoder/gatsby-theme-base/src/store/reducer';
import NoUserImage from '@assets/images/no-user.svg';
import useProfileImage from '@babelcoder/gatsby-theme-base/src/hooks/useProfileImage';
import ErrorMessage from '../ErrorMessage';
import Title from '../Title';
import Form from '../Form';
import Input from '../Input';
import SubmitButton from './SubmitButton';

const FILE_SIZE = 20 * 1024;
const SUPPORTED_FORMATS = ['image/png'];

const EditProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${up('medium')} {
    flex-direction: row;
  }
`;

const UploaderSide = styled.div`
  ${up('medium')} {
    width: 200px;
    height: 200px;
    margin-right: ${({ theme }) => theme.spacers.normal};
  }
`;

const PreviewWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    background-color: ${theme.colors.neutral.gray300};
    width: 200px;
    height: 200px;
    margin: 0 auto;

    & > svg {
      width: 200px;
    }
  `}
`;

const PreviewImage = styled.img.attrs({ alt: 'Profile Image' })`
  display: ${({ hasImage }) => (hasImage ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;

const UploadButton = styled.button.attrs({ type: 'button' })`
  ${({ theme }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: ${theme.spacers.xsmall};
    background-color: ${theme.colors.neutral.gray300};
    border-radius: ${theme.round.normal};
    border: none;
    outline: none;
    cursor: pointer;
    transition: background-color 0.25s;

    &:hover {
      background-color: ${theme.colors.main.primary};
      color: ${theme.colors.neutral.white};
    }
  `}
`;

const FileInput = styled.input.attrs({
  type: 'file',
  accept: SUPPORTED_FORMATS.join(', '),
})`
  display: none;
`;

const ProfileSide = styled.div`
  flex: 1;
  margin-top: ${({ theme }) => theme.spacers.normal};

  ${up('medium')} {
    margin-top: 0;
  }
`;

function EditProfile() {
  const { user, fullName } = useGlobalState();
  const dispatch = useGlobalDispatch();
  const imageFileRef = useRef(null);
  const previewImageRef = useRef(null);
  const { profileImage, isProfileImageLoaded } = useProfileImage();
  const [selectedImage, setSelectedImage] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    errors,
    watch,
    triggerValidation,
  } = useForm({
    mode: 'onChange',
    defaultValues: { displayName: user?.displayName, fullName },
    validationSchema: yup.object().shape({
      displayName: yup
        .string()
        .max(20, 'ความยาวน้อยกว่าหรือเท่ากับ 20 ตัวอักษร'),
      fullName: yup
        .string()
        .matches(
          /^(|(นาย|นาง|นางสาว)[ก-๏]+\s[ก-๏]+)$/,
          'ห้ามใส่เว้นวรรคหลังคำนำหน้า และชื่อกับนามสกุลให้เว้นวรรคหนึ่งครั้งโดยข้อมูลทั้งหมดต้องเป็นตัวอักษรภาษาไทย เช่น นายบาเบล โค้ดเดอร์'
        ),
      imageFile: yup
        .mixed()
        .test('fileSize', `ขนาดไฟล์ห้ามเกิน ${FILE_SIZE / 1024} KB`, (value) =>
          value.length > 0 ? value[0].size <= FILE_SIZE : true
        )
        .test(
          'fileFormat',
          `สนุนประเภทไฟล์ ${SUPPORTED_FORMATS.join(', ')} เท่านั้น`,
          (value) =>
            value.length > 0 ? SUPPORTED_FORMATS.includes(value[0].type) : true
        ),
    }),
  });
  const watchImageFile = watch('imageFile');

  const imageFieldRef = useCallback(
    (e) => {
      register(e);
      imageFileRef.current = e;
    },
    [register, imageFileRef]
  );

  const submit = useCallback(
    async ({ displayName, fullName, imageFile }) => {
      const firebaseAuth = await getFirebaseAuth();
      const firebaseStorage = await getFirebaseStorage();
      let photoURL = null;

      try {
        if (imageFile.length > 0) {
          const file = imageFile[0];
          const storageRef = firebaseStorage.ref(
            `users/${user.uid}/profile.png`
          );
          const snapshot = await storageRef.put(file);
          photoURL = snapshot.ref.toString();
        }

        (await photoURL)
          ? user.updateProfile({ displayName, photoURL })
          : user.updateProfile({ displayName });

        if (fullName) {
          const firestore = await getFirebaseFirestore();
          await firestore.doc(`users/${user.uid}`).set(
            {
              fullName,
            },
            { merge: true }
          );
        }

        dispatch({
          type: actions.setCurrentUser,
          user: { ...firebaseAuth.currentUser },
        });
        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'success',
            title: 'การดำเนินการสำเร็จ',
            message: 'ข้อมูลบุคคลของคุณได้รับการเปลี่ยนแปลงแล้ว',
          },
        });
      } catch (error) {
        dispatch({
          type: actions.showPopupMessage,
          popupMessage: {
            type: 'danger',
            title: 'การดำเนินการไม่สำเร็จ',
            message: 'เกิดข้อผิดพลาดขณะอัพเดทข้อมูลส่วนบุคคล',
          },
        });
      }
    },
    [user, dispatch]
  );

  const uploadImage = useCallback(() => imageFileRef.current.click(), [
    imageFileRef,
  ]);

  const previewImage = useCallback(
    (event) => {
      if (!event.target.files[0]) return;

      setSelectedImage(URL.createObjectURL(event.target.files[0]));

      previewImageRef.current.onload = () => {
        URL.revokeObjectURL(selectedImage);
        triggerValidation('imageFile');
      };
    },
    [
      setSelectedImage,
      previewImageRef.current,
      triggerValidation,
      selectedImage,
    ]
  );

  useEffect(() => {
    if (watchImageFile?.length > 0) return;
    if (!profileImage) return;

    previewImageRef.current.src = profileImage;
  }, [watchImageFile, profileImage, previewImageRef]);

  useEffect(() => {
    if (!fullName) return;

    setValue('fullName', fullName, {
      shouldValidate: true,
    });
  }, [fullName]);

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Title>แก้ไขข้อมูลบุคคล</Title>
      <EditProfileWrapper>
        <UploaderSide>
          <PreviewWrapper>
            <PreviewImage
              ref={previewImageRef}
              src={selectedImage}
              hasImage={
                Boolean(selectedImage) || Boolean(previewImageRef.current?.src)
              }
            />
            {isProfileImageLoaded &&
              !(previewImageRef.current?.src || selectedImage) && (
                <NoUserImage></NoUserImage>
              )}
            <UploadButton onClick={uploadImage}>อัพโหลด</UploadButton>
            <FileInput
              name="imageFile"
              ref={imageFieldRef}
              onChange={previewImage}
            />
          </PreviewWrapper>
          <ErrorMessage hasError={errors.imageFile}>
            {errors.imageFile?.message}
          </ErrorMessage>
        </UploaderSide>
        <ProfileSide>
          <Input
            type="text"
            name="displayName"
            title="ชื่อผู้ใช้งาน"
            ref={register}
            placeholder="เช่น Donald Trump"
            autoComplete="displayName"
            error={errors.displayName}
          ></Input>
          <Input
            type="text"
            name="fullName"
            title="ชื่อ-นามสกุล"
            ref={register}
            placeholder="เช่น นายบาเบล โค้ดเดอร์"
            autoComplete="fullName"
            desc="ใช้ประกอบการออกใบรับรองหลักสูตร (Certification)"
            error={errors.fullName}
          ></Input>
        </ProfileSide>
      </EditProfileWrapper>
      <SubmitButton>บันทึกข้อมูล</SubmitButton>
    </Form>
  );
}

export default EditProfile;
