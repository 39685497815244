import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from '@reach/router'

import { useGlobalState, useGlobalDispatch } from './GlobalStateProvider'
import { actions } from '../store/reducer'
import LoadingImage from '../../assets/images/loading.svg'

export const TYPES = {
  AUTH_ALLOW: 'authAllow',
  AUTH_DENY: 'authDeny',
}

function AuthGuard({
  children,
  type = TYPES.AUTH_ALLOW,
  emailMustVerified = false,
  preserveSearch = false,
  redirectTo = '/',
}) {
  const navigate = useNavigate()
  const { search } = useLocation()
  const { user, isAuthLoaded } = useGlobalState()
  const dispatch = useGlobalDispatch()
  const [pass, setPass] = useState(false)

  const authAllow = useCallback(() => {
    if (!isAuthLoaded) return

    if (!user) {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'warning',
          title: 'ไม่อนุญาตให้เข้าถึงหน้านี้',
          message: 'กรุณาเข้าสู่ระบบก่อนเข้าถึงหน้าดังกล่าว',
        },
      })

      navigate('/auth/sign-in', {
        state: { prevPath: `${redirectTo}${preserveSearch ? search : ''}` },
      })
      return
    }

    const pass = emailMustVerified ? user.emailVerified : true

    setPass(pass)

    if (pass) {
      return
    } else {
      dispatch({
        type: actions.showPopupMessage,
        popupMessage: {
          type: 'warning',
          title: 'ไม่อนุญาตให้เข้าถึงหน้านี้',
          message: 'กรุณายืนยันอีเมล์ก่อนเข้าถึงหน้าดังกล่าว',
        },
      })

      navigate(redirectTo)
    }
  }, [isAuthLoaded, user, emailMustVerified, dispatch, navigate, redirectTo])

  const authDeny = useCallback(() => {
    if (!isAuthLoaded) return
    if (!user) return setPass(true)

    navigate(redirectTo)
  }, [isAuthLoaded, user, navigate, redirectTo])

  useEffect(() => {
    switch (type) {
      case TYPES.AUTH_DENY:
        authDeny()
        break
      case TYPES.AUTH_ALLOW:
      default:
        authAllow()
    }
  }, [type, authAllow, authDeny])

  return pass ? children : <LoadingImage></LoadingImage>
}

export default AuthGuard
